import ApplicationAdapter from 'qonto/adapters/application';
import { subscriptionNamespace } from 'qonto/constants/hosts';

export default class SubscriptionAdapter extends ApplicationAdapter {
  namespace = subscriptionNamespace;
  headerOverrides = {};

  get headers() {
    return { ...this.networkManager.requestHeaders, ...this.headerOverrides };
  }

  query(store, type, query) {
    let { adapterOptions } = query;
    this.headerOverrides = adapterOptions?.customHeaders || {};
    let promise = super.queryRecord(store, type, query);
    this.headerOverrides = {};
    return promise;
  }

  updateRecord(store, type, snapshot) {
    let { adapterOptions } = snapshot;
    this.headerOverrides = adapterOptions?.customHeaders || {};
    let promise = super.updateRecord(store, type, snapshot);
    this.headerOverrides = {};
    return promise;
  }
}
